export const CREATE_OEM_REQUEST = "CREATE_OEM_REQUEST"
export const CREATE_OEM_REQUEST__SUCCESS = "CREATE_OEM_REQUEST__SUCCESS"
export const CREATE_OEM_REQUEST__FAILURE = "CREATE_OEM_REQUEST__FAILURE"
export const UPDATE_OEM_REQUEST = "UPDATE_OEM_REQUEST"
export const UPDATE_OEM_REQUEST__SUCCESS = "UPDATE_OEM_REQUEST__SUCCESS"
export const UPDATE_OEM_REQUEST__FAILURE = "UPDATE_OEM_REQUEST__FAILURE"
export const FETCH_OEMS_REQUEST = "FETCH_OEMS_REQUEST"
export const FETCH_OEMS_REQUEST__SUCCESS = "FETCH_OEMS_REQUEST__SUCCESS"
export const FETCH_OEM_BUSINESSES_REQUEST = "FETCH_OEM_BUSINESSES_REQUEST"
export const FETCH_OEM_BUSINESSES_REQUEST__SUCCESS = "FETCH_OEM_BUSINESSES_REQUEST__SUCCESS"
export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST"
export const DELETE_CLIENT_REQUEST__SUCCESS = "DELETE_CLIENT_REQUEST__SUCCESS"
export const DELETE_CLIENT_REQUEST__FAILURE = "DELETE_CLIENT_REQUEST__FAILURE"
export const IMPORT_OEM_REQUEST = "IMPORT_OEM_REQUEST"
export const IMPORT_OEM_REQUEST__SUCCESS = "IMPORT_OEM_REQUEST__SUCCESS"
export const IMPORT_OEM_REQUEST__FAILURE = "IMPORT_OEM_REQUEST__FAILURE"