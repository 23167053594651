export const CREATE_AUTH_REQUEST = "CREATE_AUTH_REQUEST"
export const CREATE_AUTH_REQUEST__SUCCESS = "CREATE_AUTH_REQUEST__SUCCESS"
export const CREATE_AUTH_REQUEST__FAILURE = "CREATE_AUTH_REQUEST__FAILURE"
export const CREATE_INVITE_CONFIRMATION_REQUEST = "CREATE_INVITE_CONFIRMATION_REQUEST"
export const CREATE_INVITE_CONFIRMATION_REQUEST__FAILURE = "CREATE_INVITE_CONFIRMATION_REQUEST__FAILURE"
export const CREATE_PASSWORD_RESET_EMAIL_REQUEST = "CREATE_PASSWORD_RESET_EMAIL_REQUEST"
export const CREATE_PASSWORD_RESET_EMAIL_REQUEST__FAILURE = "CREATE_PASSWORD_RESET_EMAIL_REQUEST__FAILURE"
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_REQUEST__FAILURE = "UPDATE_PASSWORD_REQUEST__FAILURE"
export const LOGOUT = "LOGOUT"
export const FETCH_SELF_REQUEST = "FETCH_SELF_REQUEST"
export const FETCH_SELF_REQUEST__SUCCESS = "FETCH_SELF_REQUEST__SUCCESS"
export const FETCH_SELF_REQUEST__FAILURE = "FETCH_SELF_REQUEST__FAILURE"
