export const CREATE_DEVICE_REQUEST  = "CREATE_DEVICE_REQUEST"
export const CREATE_DEVICE_REQUEST__SUCCESS  = "CREATE_DEVICE_REQUEST__SUCCESS"
export const CREATE_DEVICE_REQUEST__FAILURE  = "CREATE_DEVICE_REQUEST__FAILURE"
export const FETCH_DEVICES_REQUEST  = "FETCH_DEVICES_REQUEST"
export const FETCH_DEVICES_REQUEST__SUCCESS  = "FETCH_DEVICES_REQUEST__SUCCESS"
export const FETCH_DEVICE_REQUEST__SUCCESS  = "FETCH_DEVICE_REQUEST__SUCCESS"
export const UPDATE_DEVICE_REQUEST  = "UPDATE_DEVICE_REQUEST"
export const UPDATE_DEVICE_REQUEST__SUCCESS  = "UPDATE_DEVICE_REQUEST__SUCCESS"
export const UPDATE_DEVICE_REQUEST__FAILURE  = "UPDATE_DEVICE_REQUEST__FAILURE"
export const ADD_DEVICE_ACTION  = "ADD_DEVICE_ACTION"
export const REMOVE_DEVICE_ACTION  = "REMOVE_DEVICE_ACTION"
export const REORDER_DEVICE_ACTION  = "REORDER_DEVICE_ACTION"
export const LOAD_DEVICE_ACTIONS  = "LOAD_DEVICE_ACTIONS"
export const CREATE_PROCEDURE_DEVICE_REQUEST = "CREATE_PROCEDURE_DEVICE_REQUEST"
export const CREATE_PROCEDURE_DEVICE_REQUEST__SUCCESS = "CREATE_PROCEDURE_DEVICE_REQUEST__SUCCESS"
export const CREATE_PROCEDURE_DEVICE_REQUEST__FAILURE = "CREATE_PROCEDURE_DEVICE_REQUEST__FAILURE"
export const DELETE_DEVICE_REQUEST = "DELETE_DEVICE_REQUEST"
export const DELETE_DEVICE_REQUEST__SUCCESS = "DELETE_DEVICE_REQUEST__SUCCESS"
export const DELETE_DEVICE_REQUEST__FAILURE = "DELETE_DEVICE_REQUEST__FAILURE"
