import React, {useRef, useState } from 'react';
import ArrFileInput from '@components/Inputs/ArrFileInput';
import { useDispatch } from "react-redux";
import { updateFileList } from '@actions/template';

export default ({formKey, defaultValues, idx, objName, ...props}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [filesList, setFilesList] = useState(defaultValues || []);
  const deleteElem = (params) => {
      const newArr = filesList.filter((file, i) => i !== params.index);
      setFilesList(newArr);
    },
    handleClick = () => {inputRef.current.click()},
    handleChange = (el) => {
      const files = [...el.currentTarget.files];
      files.map(file => file.id = file.lastModified +  Math.random(10000));
      const updatedArr = [...filesList, ...files];
      setFilesList(updatedArr);
      el.currentTarget.value = null;
    };
  return <ArrFileInput onClick={handleClick} deleteElem={deleteElem} onChange={handleChange} objName={objName} idx={idx} values={filesList} {...props} inputRef={inputRef} />
}
