import React, { useState } from "react";
import ListPage from "@components/List/Page";
import PageLayout from "@components/PageLayout";
import UserList from "@containers/UserList";
import { allIds } from "@selectors/user";
import { FETCH_USERS_REQUEST } from "@types/user";
import withUserInfo from "@containers/withUserInfo";
import ModalTrigger from "@containers/ModalTrigger";
import SubmitButton from "@components/SubmitButton";
import ModalOverlay from "@components/Modal/Overlay";
import { importUsers } from "@actions/client";
import { useDispatch } from "react-redux";
import activeModal from "@containers/activeModal";

const UsersUpload = activeModal(({ oem }) => {
  const [csvFile, setcsvFile] = useState();
  const dispatch = useDispatch();
  const upload = (file) => dispatch(importUsers(oem, file));

  const uploadFile = () => {
    const formData = new FormData();
    formData.append("import_file", csvFile, csvFile.name);
    upload(formData);
  };

  return (
    <div>
      <input
        type={"file"}
        onChange={(event) => setcsvFile(event.target.files[0])}
      />
      <SubmitButton label="upload" onClick={uploadFile} />
    </div>
  );
}, "show_upload_element");

export default withUserInfo(({ user }) => (
  <PageLayout
    header="Users"
    link={[{ to: "/users/invite", text: "Invite User" }]}
    buttons={
      <ModalTrigger modal="show_upload_element">
        <SubmitButton primary label="Import Users" />
      </ModalTrigger>
    }
  >
    <ModalOverlay>
      <UsersUpload oem={user.oem} />
    </ModalOverlay>
    <UserList />
  </PageLayout>
));
/*
export default () => (
  <ListPage
    header={{header: "Users", link: {to: '/users/invite', text: "Invite User"}}}
    list={{
      type: FETCH_USERS_REQUEST,
      url: '/users',
      text: "Users",
      entityKey: "users",
      to: '/users',
      placeholder: "There are no users",
      selector: allIds
    }}
  />
)
*/
