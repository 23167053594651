export const CREATE_REGISTRATION_REQUEST = "CREATE_REGISTRATION_REQUEST"
export const CREATE_REGISTRATION_REQUEST__SUCCESS = "CREATE_REGISTRATION_REQUEST__SUCCESS"
export const CREATE_REGISTRATION_REQUEST__FAILURE = "CREATE_REGISTRATION_REQUEST__FAILURE"
export const FETCH_REGISTRATIONS_REQUEST = "FETCH_REGISTRATIONS_REQUEST"
export const FETCH_REGISTRATIONS_REQUEST__SUCCESS = "FETCH_REGISTRATIONS_REQUEST__SUCCESS"
export const FETCH_REGISTRATIONS_REQUEST__FAILURE = "FETCH_REGISTRATIONS_REQUEST__FAILURE"
export const FETCH_REGISTRATION_REQUEST = "FETCH_REGISTRATION_REQUEST"
export const FETCH_REGISTRATION_REQUEST__SUCCESS = "FETCH_REGISTRATION_REQUEST__SUCCESS"
export const FETCH_REGISTRATION_REQUEST__FAILURE = "FETCH_REGISTRATION_REQUEST__FAILURE"
export const UPDATE_REGISTRATION_REQUEST = "UPDATE_REGISTRATION_REQUEST"
export const UPDATE_REGISTRATION_REQUEST__SUCCESS = "UPDATE_REGISTRATION_REQUEST__SUCCESS"
export const UPDATE_REGISTRATION_REQUEST__FAILURE = "UPDATE_REGISTRATION_REQUEST__FAILURE"
export const DELETE_REGISTRATION_REQUEST = "DELETE_REGISTRATION_REQUEST"
export const DELETE_REGISTRATION_REQUEST__SUCCESS = "DELETE_REGISTRATION_REQUEST__SUCCESS"
export const DELETE_REGISTRATION_REQUEST__FAILURE = "DELETE_REGISTRATION_REQUEST__FAILURE"
export const FETCH_SELF_REQUEST = "FETCH_SELF_REQUEST"
export const FETCH_SELF_REQUEST__SUCCESS = "FETCH_SELF_REQUEST__SUCCESS"
export const FETCH_SELF_REQUEST__FAILURE = "FETCH_SELF_REQUEST__FAILURE"
