import * as types from "@types/oem";

export const deleteClient = (client_id) => ({
  type: types.DELETE_CLIENT_REQUEST,
  payload: client_id,
});
export const importUsers = (oem, file) => ({
  type: types.IMPORT_OEM_REQUEST,
  payload: { oem, file },
});
