export const FETCH_PROCEDURE_REQUEST = "FETCH_PROCEDURE_REQUEST";
export const FETCH_PROCEDURE_REQUEST__SUCCESS = "FETCH_PROCEDURE_REQUEST__SUCCESS";

export const CREATE_PROCEDURE_REQUEST = "CREATE_PROCEDURE_REQUEST";
export const CREATE_PROCEDURE_REQUEST__SUCCESS = "CREATE_PROCEDURE_REQUEST__SUCCESS";
export const CREATE_PROCEDURE_REQUEST__FAILURE = "CREATE_PROCEDURE_REQUEST__FAILURE";

export const UPDATE_PROCEDURE_REQUEST = "UPDATE_PROCEDURE_REQUEST";
export const UPDATE_PROCEDURE_REQUEST__SUCCESS = "UPDATE_PROCEDURE_REQUEST__SUCCESS";
export const UPDATE_PROCEDURE_REQUEST__FAILURE = "UPDATE_PROCEDURE_REQUEST__FAILURE";

export const DELETE_PROCEDURE_REQUEST = "DELETE_PROCEDURE_REQUEST";
export const DELETE_PROCEDURE_REQUEST__SUCCESS = "DELETE_PROCEDURE_REQUEST__SUCCESS";
export const DELETE_PROCEDURE_REQUEST__FAILURE = "DELETE_PROCEDURE_REQUEST__FAILURE";

export const COPY_PROCEDURE_REQUEST = "COPY_PROCEDURE_REQUEST";
export const COPY_PROCEDURE_REQUEST__SUCCESS = "COPY_PROCEDURE_REQUEST__SUCCESS";
export const COPY_PROCEDURE_REQUEST__FAILURE = "COPY_PROCEDURE_REQUEST__FAILURE";

export const UPDATE_PROCEDURE_OEM_BUSINESSES_REQUEST = "UPDATE_PROCEDURE_OEM_BUSINESSES_REQUEST";
export const UPDATE_PROCEDURE_OEM_BUSINESSES_REQUEST__SUCCESS = "UPDATE_PROCEDURE_OEM_BUSINESSES_REQUEST__SUCCESS";
export const UPDATE_PROCEDURE_OEM_BUSINESSES_REQUEST__FAILURE = "UPDATE_PROCEDURE_OEM_BUSINESSES_REQUEST__FAILURE";