import React, {useCallback, useState, useRef, useEffect } from 'react';
import activeModal from '@containers/activeModal';
import ModalTrigger from '@containers/ModalTrigger';
import { useDispatch } from "react-redux";
import { setModal } from "@actions/modal";
import {CheckBox} from '@components/Inputs';
import { addToast } from "@actions/toast";
import {useFormContext} from "react-hook-form";
import styles from "./index.module.css"


const CancelBox =({cancelSub, setCancelSub, setDisabled, subscriptionPlanId}) => {
  useEffect(() => {
    if (cancelSub) {
      setDisabled(false);
    } else {
      if (subscriptionPlanId == -1) {
        setDisabled(true);
      }
    }
  });
  return (
    <>
      <span className={styles.label}>Cancel Subscription</span>
      <input type="checkbox" name="confirm_status" value="true" checked={cancelSub} onChange={e => setCancelSub(e.target.checked)} />
    </>
  );
}

const SetupForm = ({modalPlans}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [cancelSub, setCancelSub] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState(modalPlans.subscriptionPlan)
    const [subscriptionPlanId, setSubscriptionPlanId] = useState(-1)
    const hasSubscription = modalPlans.subscription?.subscription_plan_id;
    const planLabel = hasSubscription ? "Update Subscription" : "Add Subscription";
    const closeRef = useRef();
    const dispatch = useDispatch()

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setErrorMessage("")
        setDisabled(true);
        modalPlans.updateSubscriptionPlan(subscriptionPlan)
        const localData = localStorage.getItem('login_data_4_16');
        if (localData) {
            let token = JSON.parse(localData).jwt
            const url = `/oems/${modalPlans.oem_id}/update_subscription`
            let body = {}
            if (cancelSub) {
              body = {subscription: {confirm_status: "CANCELLED"} }
            } else {
              body = {subscription: {confirm_status: "ACCEPTED", subscription_plan_id: subscriptionPlanId } }
            }
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify(body)
                    }
                )
                const data = await response.json();
                if (data.error !== undefined) {
                  setErrorMessage(data.error.join(", "));
                }
                else {
                    let toast_message = "";
                    if (cancelSub) {
                      toast_message = "Subscription cancelled.";
                    } else {
                      toast_message = `${data.plan_name} added successfully.`;
                    } 
                    dispatch(addToast("success", toast_message));
                    closeRef.current.click();
                    dispatch(setModal());
                }               
                //setPosts(data);
            } catch (e) {
                console.error(`exception ${e} during fetch of ${url}`)
            }
        }
        return
    }; // handleSubmit
    const options=modalPlans.subscriptionPlans
    const label="Plan"
    const name="plan_id"
    const placeholder="Choose a plan..."
    const props = {}
    const selectOnChange = (event) => {
        if (event.target.value){
            setDisabled(false)
            for (var i = 0; i < modalPlans.subscriptionPlans.length; i++) {
                if (modalPlans.subscriptionPlans[i].value == event.target.value) {
                    //setConfirmMessage("Selected " + modalPlans.subscriptionPlans[i].label)
                    setSubscriptionPlan(modalPlans.subscriptionPlans[i].label)
                    setSubscriptionPlanId(modalPlans.subscriptionPlans[i].value)
                }
            }

        }
        else {
            setDisabled(true)
            //setConfirmMessage("No plan selected")
            setSubscriptionPlan("No Plan Selected")
        }
    }
    //subscriptionPlanParm = "*** PLAN ****"
    return (<>
        <div className={styles.title}>Subscription Plans</div>
        <div className={styles.container}>
            <div className={styles.fields}>
                <span className={styles.label}>Plan</span>
                <span className={styles.text}>{subscriptionPlan}</span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={styles.fields}>
                    <span className={styles.label}>Select a Plan</span>

                    <select className={styles.text}{...props} onChange={e => selectOnChange(e)}
                    >
                    {placeholder && <option value="" disabled={props.unclearable}>{placeholder}</option>}
                    {(options && options.length > 0) ? (
                        options.map(option =>
                            <option key={option.value} value={option.value}>{option.description}</option>
                        )
                    ) : null}
                    </select>
                    {hasSubscription && <CancelBox cancelSub={cancelSub} setCancelSub={setCancelSub} setDisabled={setDisabled} subscriptionPlanId={subscriptionPlanId} />                    }
                </div>
                <div className={styles.buttons}>
                  <button disabled={disabled} className="primary button align_center">{planLabel}</button>
                  <ModalTrigger className={styles.hidden}>
                    <button ref={closeRef}>Close</button>
                  </ModalTrigger>
                </div>
                {errorMessage && <div style={{marginTop: '0.25rem', color: '#df1b41', fontWeight: '400', fontSize: '1.93rem'}}>{errorMessage}</div>}
            </form>
        </div>
        </>
    );
};

export default activeModal(({modalPlans}) => {
    return (
        <div className={styles.showSubscriptionsPlansModal}>
            <div className={styles.fields}>
                <SetupForm modalPlans={modalPlans} />
            </div>
        </div>

    );
}, "show_subscription_plans")