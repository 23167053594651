export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST"
export const CREATE_USER_REQUEST__SUCCESS = "CREATE_USER_REQUEST__SUCCESS"
export const CREATE_USER_REQUEST__FAILURE = "CREATE_USER_REQUEST__FAILURE"
export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST"
export const FETCH_USERS_REQUEST__SUCCESS = "FETCH_USERS_REQUEST__SUCCESS"
export const FETCH_USERS_REQUEST__FAILURE = "FETCH_USERS_REQUEST__FAILURE"
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST"
export const FETCH_USER_REQUEST__SUCCESS = "FETCH_USER_REQUEST__SUCCESS"
export const FETCH_USER_REQUEST__FAILURE = "FETCH_USER_REQUEST__FAILURE"
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST"
export const UPDATE_USER_REQUEST__SUCCESS = "UPDATE_USER_REQUEST__SUCCESS"
export const UPDATE_USER_REQUEST__FAILURE = "UPDATE_USER_REQUEST__FAILURE"
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST"
export const DELETE_USER_REQUEST__SUCCESS = "DELETE_USER_REQUEST__SUCCESS"
export const DELETE_USER_REQUEST__FAILURE = "DELETE_USER_REQUEST__FAILURE"
